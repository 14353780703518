/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, {
    className: "c-md-p"
  }, "This notice describes how we may use and disclose protected health information for treatment, payment, or healthcare operations and for other purposes that are permitted or required by law. It also describes your right to access and control your protected health information. Any hearing care professional authorized to enter your information into your record with hear.com and all employees, staff, and other members of our workforce will follow the terms of this Notice. We are required to abide by the terms of this Notice currently in effect. We are also required to notify you following a breach of your health information. You can obtain an electronic or paper copy of your health records. Ask us how to do this."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Disclosures that require your authorization")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In these cases, you have both the right and choice to tell us to:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Share information with your family, close friends, or others involved in your care"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Share information in a disaster relief situation"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In these cases, we never share your information unless you give us written consent:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Marketing purposes"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sale of your information"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How we use your protected health information")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We can share health information about you for certain situations such as:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For Treatment:"), " We may use and disclose your health information to another medical physician to receive proper treatment. For example, we may use information received from your medical provider to ensure that you receive the correct hearing care device."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Run Our Company:"), " We can use and share your health information to run our practice, improve your care, and contact you when necessary."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For Payment:"), " We may disclose you protected health information to obtain payment for services we provide to you. For example, we may contact health insurance provider to determine whether your plan will pay a part of the cost of your hearing care device."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For Health Care Operations:"), " We may use and disclose your protected health information for our health care operations. Health care operations are necessary to run our company, maintain licensure, and make sure our customers receive quality information on services and products."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Public Health and Safety")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Preventing disease"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Helping with product recalls"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reporting adverse reactions to medications"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reporting suspected abuse, neglect, or domestic violence"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Preventing or reducing a serious threat to anyone’s health or safety"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Research:"), " We can use or share your information for health research."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Compliance with the Law: We will share information about you if state or federal laws require it, including with the Department of Health and Human Services should they want to see that we’re complying with federal privacy laws. We can share health information about you in response to a court or administrative order, or in response to a subpoena."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Address Workers’ Compensation, law enforcement, and other government requests")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "For workers’ compensation claims"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "For law enforcement purposes or with a law enforcement official"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "With health oversight agencies for activities authorized by law"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Specific Health Information:"), " State or federal law may provide additional protection on some speciﬁc medical health information. For example, these laws may prohibit us from disclosing information related to HIV/AIDS, mental health, alcohol or substance abuse and genetic information without your authorization. In these situations, we will follow the requirements of the state or federal law."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Your Rights Regarding Medical Information About You")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You have the following rights regarding medical information we maintain about you:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to inspect and copy:"), " With certain exceptions, you have the right to inspect and/or receive an electronic or paper copy of your medical and billing records or any other of our records that are used by us to make decisions about you. We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to request an amendment:"), " If you feel that the medical information we have about you is incorrect or incomplete, you may ask us to amend the information. You have the right to request an amendment for as long as the information is kept by or for hear.com in your medical and billing records or any other of our records that are used by us to make decisions about you. If we deny your request, we will give you a written explanation of why we did not make the amendment and explain your rights."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right an accounting of disclosures:"), " You have the right to receive a list of certain disclosures we have made of your medical information in the six years prior to your request. This list will not include every disclosure made, including those disclosures made for treatment, payment and health care operations purposes, or those disclosures made directly to you or with your consent. We will provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within a 12-month period."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to request restrictions:"), " You have the right to request a restriction or limitation on the medical information we use or disclose about you for treatment, payment, or health care operations. We are not required to agree to your request, and we may deny your request if it would affect your care. If you request that we not disclose certain medical information to your health insurer and that medical information relates to a health care product or service for which we, otherwise, have received payment from you or on your behalf, and in full, then we must agree to that request."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to request confidential communications:"), " You have the right to request that we communicate with you about medical matters in a specific way. If you want us to communicate with you in a specific way, you will need to give us details about how to contact you. You also will need to give us information as to how billing will be handled. We will honor reasonable requests. However, if we are unable to contact you using the requested means or locations, we may contact you using any information we have."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to be notified in the event of a breach:"), " We will notify you if your medical information has been “breached,” which means that your medical information has been used or disclosed in a way that is inconsistent with law and results in it being compromised."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to receive a paper copy of this Notice:"), " You have the right to receive a paper copy of this Notice. You may ask us to give you a paper copy of this Notice at any time, even if you have received the notice electronically."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to choose someone to act on your behalf:"), " If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information. We will make sure the person has this authority and can act for you before we take any action."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Right to file a complaint or concern:"), " If you feel we have violated your rights, you may file a complaint by contacting us using the contact information at the end of this notice. You can also file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting ", React.createElement("a", {
    href: "https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html",
    target: "_blank"
  }, "www.hhs.gov/ocr/privacy/hipaa/complaints/"), "."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Our Responsibilities")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We are required by law to maintain the privacy and security of your protected health information. We will let you know if a breach occurs that may have compromised the privacy or security of your information. We must follow the duties and privacy practices described in this notice and give you a copy of it. We will not use or share your information other than as described here unless you tell us we can in writing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Changes to the Terms of this Notice and Contact Information")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We may change the terms of this Notice, and the changes will apply to all information we have about you. The new notice will be available upon request and on our website."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have questions or would like further information about this Notice, please call 720-410-9535, visit hear.com, or contact the address below:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "hear.com")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "396 Alhambra Circle, Suite 1200", React.createElement(_components.br), "\n", "Coral Gables, FL 33134"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Effective Date: ", React.createElement(_components.strong, null, "12/02/2021")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
